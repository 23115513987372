<template>
  <div>
    <spinner v-if="loading" color="#000" />
    <template v-else>
      <edit-register-layout @update="loadData" />
      <delete-modal
        type="deleteRegisterLayout"
        entity-translation-key="registerLayout"
        label="name"
        @update="$router.push('/register_layouts')"
      />
      <edit-button-modal @update="loadData" />

      <entity-card
        :fields="[{ key: 'cols', label: 'Spalten' }, { key: 'rows', label: 'Zeilen' }]"
        :entity="entity"
        :title="entity.name"
        big-heading
      >
        <template #action>
          <div class="entity-detail-page-actions">
            <button
              class="small white mr-2"
              @click="$store.dispatch('modals/openModal', { entity, modal: 'deleteRegisterLayout'})"
              v-text="$t('general.action.delete')"
            />
            <button v-t="'general.action.edit'" class="small" @click="$store.dispatch('modals/openModal', { entity, modal: 'editRegisterLayout' })" />
          </div>
        </template>
        <template #additional>
          <div class="mt-8 register_layout_container">
            <div
              v-for="(row, r) in buttons"
              :key="'row-' + r"
              class="grid grid-rows-1 mb-4 gap-4"
              :class="['grid-cols-' + row.length]"
            >
              <div
                v-for="(btn, c) in row"
                :key="'col-' + c"
                :class="{ 'bg-violet-light': btn != null }"
                class="p-2 relative aspect-1 cursor-pointer register_layout_button"
                @click="editButton(btn, c, r)"
              >
                <template v-if="btn != null">
                  <img v-if="btn.icon" :src="btn.iconUrl" class="w-full h-48 object-contain mx-auto" />
                  <div :style="{ 'font-size': `${btn.fontSize}px` }" v-text="btn.text" />
                </template>
                <div class="absolute bottom-2 right-2 text-2xs">
                  [{{ c }}, {{ r }}]
                </div>
              </div>
            </div>
          </div>
        </template>
      </entity-card>
    </template>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import axios from '@/services/axios';
import Spinner from '@/components/utils/spinner.vue';
import EntityCard from '@/components/shared/EntityCard.vue';
import EditRegisterLayout from '@/components/entities/register_layout/EditRegisterLayout.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import EditButtonModal from '@/components/entities/register_layout/EditButtonModal.vue';

export default {
  name: 'RegisterLayoutDetailPage',
  components: {
    EditButtonModal,
    DeleteModal,
    EditRegisterLayout,
    EntityCard,
    Spinner,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const entity = ref(null);
    const buttons = ref([]);
    const button = ref({});
    const loading = ref(true);
    const editModal = ref(null);
    const deleteModal = ref(null);
    const editButtonModal = ref(null);

    const loadData = async () => {
      loading.value = true;
      try {
        const { data: layout } = await axios.get(`/register_layouts/${route.params.id}`);
        entity.value = layout;

        // Initialize buttons grid
        buttons.value = [];
        if (entity.value.rows && entity.value.cols) {
          for (let i = 0; i < entity.value.rows; i += 1) {
            buttons.value.push([]);
            for (let j = 0; j < entity.value.cols; j += 1) {
              buttons.value[i].push(null);
            }
          }
        }

        // Fill buttons at correct place
        if (entity.value.buttons.length) {
          const { data: buttonResponse } = await axios.get('/buttons', {
            params: {
              pagination: false,
              id: layout.buttons.map((b) => b['@id'].split('/').reverse()[0]),
            },
          });
          if (buttonResponse['hydra:member']) {
            buttonResponse['hydra:member'].forEach((b) => {
              buttons.value[b.row][b.col] = {
                ...b,
                targetProduct: b.targetProduct?.['@id'],
                targetProductGroup: b.targetProductGroup?.['@id'],
                targetRegisterLayout: b.targetRegisterLayout?.['@id'],
                icon: b.icon?.['@id'],
                iconUrl: b.icon ? `${window.env.VUE_APP_API_URL || process.env.VUE_APP_API_URL}${b.icon?.contentUrl}` : null,
              };
            });
          }
        }
      } catch (err) {
        console.error(err, err.message, err.response);
      }
      loading.value = false;
    };

    const editButton = (value, col = 1, row = 1) => {
      if (value === null) {
        button.value = {
          text: '',
          col,
          row,
          type: 0,
          registerLayout: entity.value['@id'],
          targetProduct: null,
          targetProductGroup: null,
          targetRegisterLayout: null,
          icon: null,
        };
      } else {
        button.value = value;
      }
      store.dispatch('modals/openModal', { entity: button.value, modal: 'editButton' });
    };

    onMounted(async () => {
      await loadData();
    });

    return {
      entity,
      buttons,
      button,
      loading,
      editModal,
      deleteModal,
      editButtonModal,
      loadData,
      editButton,
    };
  },
};
</script>

<style lang="scss" scoped>
  .register_layout_container {
    overflow-x: auto;

    > div {
      width: 1275px;
      white-space: pre;
      text-align: center;
    }
  }
  .register_layout_button {
    background-color: rgb(231, 206, 215);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    border-radius: 25%;
  }
</style>
