<template>
  <div class="center-between mb-4">
    <h1 class="m-0" v-text="$t('registerLayout.label', 2)" />
    <button
      class="small"
      @click="openModal({
        name: '',
        cols: 1,
        rows: 1,
      }, 'editRegisterLayout')"
    >
      +
    </button>
  </div>

  <entity-table
    v-model:sortBy="sortBy"
    v-model:sortDirection="sortDirection"
    :entities="entities"
    :fields="fields"
    :current-page="currentPage"
    :total-items="totalItems"
    :loading="loadingEntities"
    @click:delete="openModal($event, 'deleteRegisterLayout')"
    @update:sort="loadEntities()"
    @update:page="currentPage = $event; loadEntities()"
  />

  <edit-register-layout @update="loadEntities()" />
  <delete-modal
    type="deleteRegisterLayout"
    entity-translation-key="registerLayout"
    label="name"
    @update="loadEntities()"
  />
</template>

<script>
import { useStore } from 'vuex';
import useEntityFields from '@/hooks/api/fields';
import useLoadEntities from '@/hooks/api/loadEntities';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import EditRegisterLayout from '@/components/entities/register_layout/EditRegisterLayout.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';

export default {
  components: { DeleteModal, EditRegisterLayout, EntityTable },
  setup() {
    const store = useStore();
    const { registerLayout: fields } = useEntityFields();
    const {
      entities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadingEntities,
      loadEntities,
    } = useLoadEntities('/register_layouts', { sort: 'name' });
    const openModal = (entity, modal) => {
      store.dispatch('modals/openModal', { entity, modal });
    };

    return {
      fields,
      entities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadingEntities,
      loadEntities,
      openModal,
    };
  },
};
</script>
