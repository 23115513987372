<template>
  <modal-form
    v-slot="{ properties, errors }"
    type="editRegisterLayout"
    :options="{
      properties: {
        name: '',
        rows: 1,
        cols: 1,
      },
      label: 'Kassenlayout',
      postUri: '/register_layouts',
    }"
    @update="$emit('update')"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" :error="errors.name" required />
    <fancy-input
      v-model="properties.rows"
      :label="$t('registerLayout.row.label', 2)"
      type="number"
      :min="1"
      :max="8"
      :step="1"
      :error="errors.rows"
      required
      class="mt-4"
    />
    <fancy-input
      v-model="properties.cols"
      :label="$t('registerLayout.col.label', 2)"
      type="number"
      :min="1"
      :max="8"
      :step="1"
      :error="errors.cols"
      required
      class="mt-4"
    />
  </modal-form>
</template>
<script>
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';

export default {
  name: 'EditRegisterLayout',
  components: { FancyInput, ModalForm },
  emits: ['update'],
};
</script>
