<template>
  <div>
    <modal-form
      type="editButton"
      name-prop="text"
      :options="{
        properties: {
          text: '',
          col: 1,
          row: 1,
          type: 0,
          registerLayout: null,
          targetProduct: null,
          targetProductGroup: null,
          targetRegisterLayout: null,
          icon: null,
          backgroundColor: null,
          foregroundColor: null,
          fontSize: 16,
        },
        label: 'Button',
        postUri: '/buttons',
      }"
      @update="$emit('update')"
    >
      <template #default="{ properties }">
        <fancy-input v-model="properties.text" :label="$t('general.field.text')" required textarea />
        <div class="grid grid-cols-2 gap-4 mt-4">
          <fancy-input :model-value="properties.col" :label="$t('registerLayout.col.label')" type="number" readonly />
          <fancy-input :model-value="properties.row" :label="$t('registerLayout.row.label')" type="number" readonly />
        </div>
        <simple-dropdown
          v-model="properties.type"
          :options="buttonTypes"
          :label="$t('button.type.label')"
          class="mt-4"
          required
        />
        <multiselect
          v-if="properties.type === 10"
          v-model="properties.targetProduct"
          :options="async (q) => productOptions(q, properties.targetProduct)"
          :loading="loadingProducts"
          :filter-results="false"
          :required="properties.type === BUTTON_TYPES.BUTTON_TYPE_PRODUCT"
          :form-label="$t('product.label')"
        >
          <template #option="{ option }">
            {{ `${option.name} (${option.productNumber})` }}
          </template>
          <template #singlelabel="{ value }">
            <div v-if="value" class="multiselect-single-label">
              {{ `${value.name} (${value.productNumber})` }}
            </div>
          </template>
        </multiselect>
        <multiselect
          v-if="properties.type === 20"
          v-model="properties.targetProductGroup"
          :options="async (q) => productGroupOptions(q, properties.targetProductGroup)"
          :loading="loadingProductGroups"
          :required="properties.type === BUTTON_TYPES.BUTTON_TYPE_GROUP"
          :filter-results="false"
          :form-label="$t('productGroup.label')"
        />
        <multiselect
          v-if="properties.type === 30"
          v-model="properties.targetRegisterLayout"
          :options="async (q) => registerLayoutOptions(q, properties.targetRegisterLayout)"
          :loading="loadingRegisterLayouts"
          :required="properties.type === BUTTON_TYPES.BUTTON_TYPE_REGISTER_LAYOUT"
          :filter-results="false"
          :form-label="$t('registerLayout.label')"
        />
        <multiselect
          v-model="properties.icon"
          :options="async (q) => iconOptions(q, properties.icon)"
          :loading="loadingIcons"
          :filter-results="false"
          :form-label="$t('button.icon.label')"
        >
          <template #option="{ option }">
            <product-icon class="option-icon" :icon="option" /> {{ option.name }}
          </template>
          <template #singlelabel="{ value }">
            <div class="multiselect-single-label">
              <product-icon class="option-icon" :icon="value" /> {{ value.name }}
            </div>
          </template>
        </multiselect>
        <fancy-input
          v-model="properties.backgroundColor"
          :label="$t('button.backgroundColor.label')"
          type="color"
        />
        <fancy-input
          v-model="properties.foregroundColor"
          :label="$t('button.foregroundColor.label')"
          type="color"
        />
        <fancy-input v-model="properties.fontSize" :label="$t('button.fontSize.label')" type="number" />
      </template>

      <template #additionalButtons="{ entity }">
        <button
          v-if="entity['@id']"
          class="red mr-4"
          type="button"
          @click.prevent="openDeleteButtonModal"
          v-text="$t('general.action.delete')"
        />
      </template>
    </modal-form>

    <delete-modal
      type="deleteButton"
      entity-translation-key="button"
      label="text"
      @update="$emit('update')"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import useLoadOptions from '@/hooks/api/loadOptions';
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import SimpleDropdown from '@/components/utils/forms/SimpleDropdown.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import ProductIcon from '@/components/shared/ProductIcon.vue';
import { BUTTON_TYPES, TABLE_SHAPES } from '@/constants';
import FancyCheckbox from '@/components/utils/forms/FancyCheckbox.vue';

export default {
  name: 'EditButtonModal',
  components: {
    FancyCheckbox,
    ProductIcon,
    Multiselect,
    DeleteModal,
    SimpleDropdown,
    ModalForm,
    FancyInput,
  },
  emits: ['update'],
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const buttonTypes = [];
    Object.entries(BUTTON_TYPES).forEach((entry) => {
      const [key, value] = entry;
      buttonTypes.push({ key, label: t(`button.type.${key}`), value });
    });

    const {
      loadOptions: productOptions,
      loading: loadingProducts,
    } = useLoadOptions('/products', ['name', 'productNumber']);
    const {
      loadOptions: productGroupOptions,
      loading: loadingProductGroups,
    } = useLoadOptions('/product_groups', 'name');
    const {
      loadOptions: registerLayoutOptions,
      loading: loadingRegisterLayouts,
    } = useLoadOptions('/register_layouts', 'name');
    const {
      loadOptions: iconOptions,
      loading: loadingIcons,
    } = useLoadOptions('/images', 'name');

    const openDeleteButtonModal = async () => {
      const e = {
        '@id': store.state.modals.entity['@id'],
        text: store.state.modals.entity.text,
      };
      await store.dispatch('modals/closeModal', 'editButton');
      await store.dispatch('modals/openModal', {
        entity: e,
        modal: 'deleteButton',
      });
    };

    return {
      buttonTypes,
      productOptions,
      productGroupOptions,
      registerLayoutOptions,
      loadingProducts,
      loadingProductGroups,
      loadingRegisterLayouts,
      iconOptions,
      loadingIcons,
      openDeleteButtonModal,
      BUTTON_TYPES,
    };
  },
};
</script>

<style scoped lang="scss">
.option-icon {
  max-width: 40px;
}
</style>
